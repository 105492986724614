import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Icon, Label, Pagination, PaginationProps, SemanticCOLORS } from "semantic-ui-react";

import { useIntl } from "react-intl";
import { RowInfo } from "react-table-6";

import ButtonLoadCheck, { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";

import { getLocationsSet, tidyLocation2 } from "react-lib/apps/common/CUDENT/DentalRecordUtil";
import PasswordRecheck from "react-lib/apps/common/PasswordRecheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import ActionButton from "./ActionButton";
import CardImagingResultUX from "./CardImagingResultUX";
import ModalGallery from "./ModalGallery";
import ModImagingAuditLog from "./ModImagingAuditLog";
import ModXrayDetail from "./ModXrayDetail";

// Interface
import {
  ImagingQueueSerializer,
  ORDER_STATUS,
  OrderStatusType,
  RESULT_SUMMARY,
  RESULT_SUMMARY_LABEL,
} from "./sequence/ImagingHandler";
import { State as OrderState } from "./sequence/ImagingOrder";
import {
  ACTIONS,
  BTN_ACTS,
  CARD_IMAGING_RESULT,
  DIAGNOSIS_SEARCH_ID,
  ImagingOrderResultSerializer,
  MasterOptionsType,
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/ImagingResult";
import { ORDER_STATUS_COLORS } from "./sequence/ImagingWorkList";

import CONFIG from "config/config";

// Types
type CardImagingResultProps = PickedProps & {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  ImagingResultSequence: State["ImagingResultSequence"];
  cardStyle?: CSSProperties;

  // Dental disgram
  clinicalFindingId?: number | null;
  clinicalFindingIndex?: number | null;
  clinicalFindings: Record<string, any>[];
  clinicaltags?: { items: any[]; name: string };
  // ----- #

  // data
  django?: {
    authenticated: true;
    csrf: string;
    division: number;
    user: {
      id: number;
      full_name: string;
      role_types: string[];
      token: string;
    };
  };
  doctorId?: number;
  encounterId?: number | null; // filter by encounter id
  filterClinicalFindingIds: number[];
  hideColumnResult?: boolean;
  languageUX?: string;
  modXrayDetail: OrderState["modXrayDetail"];
  orderStatus?: OrderStatusType; // filter by order status
  organ?: { items: any[]; name: string };
  readOnly?: boolean;
  tableHeight?: CSSProperties["height"];
  // options
  masterOptions?: MasterOptionsType;
};

type ReportActionType = "APPROVE" | "EDIT" | "REPORT" | "UNAPPROVE";

type Styles = Record<
  "button" | "center" | "label" | "logIcon" | "pacsIcon" | "printBtn" | "warningIcon",
  CSSProperties
>;

// Const
const styles: Styles = {
  button: { minWidth: "max-content" },
  center: { display: "grid", placeContent: "center", textAlign: "center" },
  label: { background: "#f5f5f5", lineHeight: 1.25, opacity: 0.75 },
  logIcon: { fontSize: "1.15rem" },
  pacsIcon: { marginLeft: "0.5rem" },
  printBtn: { marginTop: "-5px", minWidth: "max-content", padding: "0.65em 1em" },
  warningIcon: {
    fontSize: "1.2em",
    margin: "0 0 0 0.5rem",
  },
};

const IMAGES = {
  checkGreen: "/static/images/x-ray/check-green.png",
  checkYellow: "/static/images/x-ray/check-yellow.png",
  commentGreen: "/static/images/x-ray/comment-outline-green.png",
  commentGrey: "/static/images/x-ray/comment-outline-grey.png",
  dismiss: "/static/images/x-ray/dismiss-red.png",
};

const CHECK_ICONS = {
  APPROVED: { alt: "Green checkmark icon", src: IMAGES.checkGreen },
  REPORTED: { alt: "Yellow checkmark icon", src: IMAGES.checkYellow },
};

const BUTTON_CONFIG: Record<
  "APPROVE" | "EDIT",
  Pick<ButtonLoadCheckCustomProps, "action" | "color" | "title">
> = {
  APPROVE: {
    action: ["APPROVE", "UNAPPROVE"],
    color: ["green", "red"],
    title: ["APPROVE", "UNAPPROVE"],
  },
  EDIT: { action: ["REPORT", "EDIT"], color: ["blue", "yellow"], title: ["SAVE", "EDIT"] },
};

const PAGINATION_CONFIG = {
  ellipsis: {
    icon: true,
    content: <Icon name="ellipsis horizontal" />,
  },
  first: {
    icon: true,
    content: <Icon name="angle double left" />,
  },
  last: {
    icon: true,
    content: <Icon name="angle double right" />,
  },
  next: { icon: true, content: <Icon name="angle right" /> },
  prev: { icon: true, content: <Icon name="angle left" /> },
};

const LIMIT = 20;

const CardImagingResult = (props: CardImagingResultProps) => {
  const intl = useIntl();

  const [activePage, setActivePage] = useState<number>(1);

  const [modAuditLog, setModAuditLog] = useState<ImagingQueueSerializer | null>(null);

  const encounterIdRef = useRef<number | null | undefined>(-1);

  // Callback Effect
  const getImagingResult = useCallback((data = {}) => {
    props.runSequence({
      sequence: "ImagingResult",
      orderStatus: props.orderStatus,
      restart: true,
      ...data,
    });
  }, []);

  // Effect
  useEffect(() => {
    // *: เนื่องจากมีการ effect เข้ามาโดยที่ encounter id ยังไม่เปลี่ยนแปลง
    if (encounterIdRef.current === props.encounterId) {
      return;
    }

    encounterIdRef.current = props.encounterId;

    if ("encounterId" in props) {
      if (props.encounterId) {
        getImagingResult({ encounterId: props.encounterId });
      } else {
        props.runSequence({
          sequence: "ImagingResult",
          clear: true,
        });
      }
    } else {
      getImagingResult();
    }
  }, [props.encounterId]);

  // Memo callback
  const selectedOrder = useMemo(
    () => props.ImagingResultSequence?.selectedOrder,
    [props.ImagingResultSequence?.selectedOrder]
  );

  // Callback
  const handleOpenModDetail = useCallback(
    (data: any) => (e: SyntheticEvent) => {
      e.stopPropagation();

      props.runSequence({
        sequence: "ImagingResult",
        action: ACTIONS.MOD_XRAY,
        data,
        type: "OPEN",
      });
    },
    []
  );

  const handleOpenAuditLog = useCallback(
    (data: ImagingQueueSerializer) => (e: SyntheticEvent) => {
      e.stopPropagation();

      props.runSequence({
        sequence: "ImagingResult",
        action: ACTIONS.GET_AUDIT_LOG,
        orderItemId: data.id,
      });

      setModAuditLog(data);
    },
    []
  );

  const handleChangeValue = useCallback(
    (e: SyntheticEvent | null, data: { checked?: boolean; name: string; value: any }) => {
      const value = typeof data.checked === "boolean" ? data.checked : data.value;
      const name = data.name as keyof ImagingOrderResultSerializer;

      const detail = props.ImagingResultSequence?.reportDetail || {};

      detail[name] = value;

      props.setProp("ImagingResultSequence.reportDetail", { ...detail });
    },
    [props.ImagingResultSequence?.reportDetail]
  );

  const handleSelectOrder = (rowInfo?: RowInfo) => {
    const row = props.ImagingResultSequence?.orderList?.[rowInfo?.index || 0];

    if (row) {
      props.runSequence({
        sequence: "ImagingResult",
        action: ACTIONS.SELECT_ORDER,
        data: row,
      });
    }
  };

  const handleGetTrProps = useCallback(
    (state: any, rowInfo?: RowInfo) => {
      const rowId = rowInfo?.original.id;
      const isLoading = props.buttonLoadCheck?.[BTN_ACTS.SELECT_ORDER];
      const isSelectedOrder = !!selectedOrder?.id && selectedOrder.id === rowId;

      return {
        className: isSelectedOrder ? "blueSelectedRow" : "",
        // style: { cursor: isLoading && rowId ? "progress" : "" },
        onClick: () => {
          if (!isLoading) {
            handleSelectOrder(rowInfo);
          }
        },
      };
    },
    [handleSelectOrder, props.buttonLoadCheck, selectedOrder]
  );

  const handleEdit = useCallback((actionType: "APPROVE" | "EDIT" | "REPORT" | "UNAPPROVE") => {
    props.runSequence({
      sequence: "ImagingResult",
      action: ACTIONS.EDIT,
      actionType,
      card: CARD_IMAGING_RESULT,
    });
  }, []);

  const handlePageChange = useCallback((e: SyntheticEvent, data: PaginationProps) => {
    setActivePage(Number(data.activePage));
  }, []);

  const handleClose = useCallback(() => {
    props.runSequence({
      sequence: "ImagingResult",
      action: ACTIONS.CLOSE_ORDER,
    });
  }, []);

  const handleClickPacs = useCallback(() => {
    if (selectedOrder) {
      props.runSequence({
        sequence: "ImagingResult",
        action: ACTIONS.OPEN_PACS,
        card: CARD_IMAGING_RESULT,
        data: selectedOrder,
      });
    }
  }, [selectedOrder]);

  const handleCloseModXray = useCallback(() => {
    props.runSequence({
      sequence: "ImagingResult",
      action: ACTIONS.MOD_XRAY,
      type: "CLOSE",
    });
  }, []);

  const handleCloseModLog = useCallback(() => {
    setModAuditLog(null);

    props.setProp("ImagingResultSequence.auditLogList", []);
  }, []);

  const handleCloseModalGallery = useCallback(() => {
    props.setProp("ImagingResultSequence.pacsGalleryDetail", null);
  }, []);

  const handlePrintReport: BLClickHandler<typeof ACTIONS.PRINT_REPORT> = useCallback(
    (e, data) => {
      props.runSequence({
        sequence: "ImagingResult",
        action: data.name,
        card: CARD_IMAGING_RESULT,
        patient: props.selectedPatient,
      });
    },
    [props.selectedPatient]
  );

  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${CARD_IMAGING_RESULT}`, null);
  }, []);

  const handleChangePassword = useCallback(
    (value: string) => {
      handleChangeValue(null, { name: "password", value });
    },
    [handleChangeValue]
  );

  // Memo
  const orderItemStatus = useMemo(
    () => props.ImagingResultSequence?.reportDetail?.order_item_status || "",
    [props.ImagingResultSequence?.reportDetail?.order_item_status]
  );

  const hideApprove = useMemo(
    () =>
      !(
        ([ORDER_STATUS.REPORTED, ORDER_STATUS.APPROVED] as string[]).includes(orderItemStatus) &&
        props.django?.user.role_types.includes("DOCTOR")
      ),
    [orderItemStatus, props.django]
  );

  const filterOrderList = useMemo(() => {
    const offset = (activePage - 1) * LIMIT;

    return (props.ImagingResultSequence?.orderList || []).slice(offset, offset + LIMIT);
  }, [activePage, props.ImagingResultSequence?.orderList]);

  const divisionName = useMemo(
    () =>
      props.masterOptions?.division?.find(
        (item) => item.value === props.ImagingResultSequence?.imagingItemDetail?.order_div
      )?.text || "",
    [props.ImagingResultSequence?.imagingItemDetail, props.masterOptions?.division]
  );

  const statusDetail = useMemo(() => {
    const status = selectedOrder?.status || "";

    return {
      color: ORDER_STATUS_COLORS[status],
      label: ORDER_STATUS[status],
    };
  }, [selectedOrder?.status]);

  const allowedApprove = useMemo(() => {
    const detail = props.ImagingResultSequence?.reportDetail || {};

    // !!detail.username &&
    return !!detail.password && !!detail.result && !!detail.impression;
  }, [props.ImagingResultSequence?.reportDetail]);

  const isRadiologist = useMemo(
    () => props.doctorId === selectedOrder?.radiologist_id,
    [selectedOrder]
  );

  const readOnly = useMemo(
    () =>
      // เมื่อ status เป็น approve, props.readOnly, แพทย์ผู้ใช้งานไปตรงกับ radiologist ที่ execute
      orderItemStatus === ORDER_STATUS.APPROVED || props.readOnly || !isRadiologist,
    [isRadiologist, orderItemStatus, props.doctorId, props.readOnly]
  );

  const showApprove = useMemo(
    () =>
      // เมื่อ status เป็น reported, approved และ !props.readOnly
      // แพทย์รังสีอื่นๆสามารถ approve และ unapprove ได้
      !(hideApprove || props.readOnly),
    [hideApprove, props.readOnly]
  );

  const allowedEdit = useMemo(() => {
    const detail = props.ImagingResultSequence?.reportDetail || {};

    return allowedApprove && (detail.id ? !!detail.edit_reason : true);
  }, [allowedApprove, props.ImagingResultSequence?.reportDetail]);

  const locationsName = useMemo(() => {
    const locationsName: string = selectedOrder?.locations_name || "";

    const locationsSet = getLocationsSet(locationsName, props.organ?.items || []);

    return tidyLocation2(locationsSet, props.organ?.items || []).join(",");
  }, [props.organ, selectedOrder]);

  const executionNote = useMemo(
    () => (selectedOrder?.executed_by_name ? selectedOrder.execution_note : ""),
    [selectedOrder]
  );

  const suspectedDiagnosisName = useMemo(() => {
    const searchList: any[] = props.searchedItemListWithKey?.[DIAGNOSIS_SEARCH_ID] || [];

    const foundItem = searchList.find(
      (item) => item.id === props.ImagingResultSequence?.imagingItemDetail?.suspected_diagnosis
    );

    return (foundItem?.name || "") as string;
  }, [props.ImagingResultSequence?.imagingItemDetail, props.searchedItemListWithKey]);

  // เมื่อบันทึกแล้วและสถานะไม่เป็น approved และ radiologist ตรงกับตัวเอง
  const showEditReason = useMemo(
    () =>
      !!props.ImagingResultSequence?.reportDetail?.id &&
      orderItemStatus !== ORDER_STATUS.APPROVED &&
      isRadiologist &&
      !props.readOnly,
    [isRadiologist, orderItemStatus, props.ImagingResultSequence?.reportDetail?.id, props.readOnly]
  );

  const imagingOrderItems = useMemo(
    () =>
      filterOrderList.map((item: any, index: number) => {
        const icon: { alt: string; src: string } | undefined = CHECK_ICONS[item.status];

        return {
          ...item,
          datetime: <div style={styles.center}>{item.created}</div>,
          doctor_name: <div style={styles.center}>{item.care_provider}</div>,
          log: (
            <div style={{ ...styles.center, height: "100%" }}>
              <Icon
                id={`CardImagingResult-Items-Log-${item.order_number}`}
                aria-hidden="true"
                aria-label="open modal audit log"
                name="history"
                style={styles.logIcon}
                link
                onClick={handleOpenAuditLog(item)}
              />
            </div>
          ),
          order_no: <div id={`CardImagingResult-Items-Order-${item.order_number}`} style={styles.center}>{item.order_number}</div>,
          order_status: (
            <div
              id={`CardImagingResult-Items-Status-${item.order_number}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                textTransform: "capitalize",
              }}
            >
              {ORDER_STATUS[item.status].toLowerCase()}

              {item.result_summary &&
                RESULT_SUMMARY_LABEL[item.result_summary] === RESULT_SUMMARY.ABNORMAL && (
                  <Icon color="red" name="warning circle" style={styles.warningIcon} />
                )}
            </div>
          ),
          request: (
            <div style={{ height: "100%", position: "relative" }}>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 30px" }}>
                <div>{item.product_name}</div>
                <div>
                  <img
                    id={`CardImagingResult-Items-Request-${item.order_number}`}
                    alt={item.note ? "comment outline green" : "comment outline grey"}
                    aria-hidden="true"
                    aria-label="open modal x-ray detail"
                    src={item.note ? IMAGES.commentGreen : IMAGES.commentGrey}
                    // style
                    style={{ cursor: "pointer", width: "25px" }}
                    onClick={handleOpenModDetail(item)}
                  />
                </div>
              </div>
            </div>
          ),
          result: (
            <div style={{ ...styles.center, height: "100%" }}>
              <img
                id={`CardImagingResult-Items-Result-${item.order_number}`}
                alt={icon?.alt || "dismiss red"}
                src={icon?.src || IMAGES.dismiss}
                style={{ height: "100%", width: "20px" }}
              />
            </div>
          ),
          type: <div style={styles.center}>{item.group_name}</div>,
        };
      }),
    [filterOrderList, handleOpenAuditLog, handleOpenModDetail]
  );

  const buttonApprove = useMemo(
    () =>
      !hideApprove && (
        <ButtonLoadCheckCustom
          setProp={props.setProp}
          // data
          action={BUTTON_CONFIG.APPROVE.action}
          color={BUTTON_CONFIG.APPROVE.color}
          disabled={!allowedApprove}
          // config
          switch={orderItemStatus === ORDER_STATUS.APPROVED}
          title={BUTTON_CONFIG.APPROVE.title}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          onClick={handleEdit}
        />
      ),
    [allowedApprove, handleEdit, hideApprove, orderItemStatus, props.buttonLoadCheck]
  );

  const buttonEdit = useMemo(
    () => (
      <ButtonLoadCheckCustom
        setProp={props.setProp}
        // data
        action={BUTTON_CONFIG.EDIT.action}
        color={BUTTON_CONFIG.EDIT.color}
        disabled={!allowedEdit || orderItemStatus === ORDER_STATUS.APPROVED}
        // config
        switch={!!props.ImagingResultSequence?.reportDetail?.id}
        title={BUTTON_CONFIG.EDIT.title}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        onClick={handleEdit}
      />
    ),
    [
      allowedEdit,
      handleEdit,
      orderItemStatus,
      props.buttonLoadCheck,
      props.ImagingResultSequence?.reportDetail?.id,
    ]
  );

  const buttonPrintReport = useMemo(
    () =>
      ([ORDER_STATUS.REPORTED, ORDER_STATUS.APPROVED] as string[]).includes(
        selectedOrder?.status || ""
      ) && (
        <ButtonLoadCheck
          // function
          setProp={props.setProp}
          // config
          color={"yellow"}
          icon="print"
          name={ACTIONS.PRINT_REPORT}
          // data
          paramKey={BTN_ACTS.PRINT_REPORT}
          size="small"
          style={styles.printBtn}
          title={"Print Report"}
          fluid
          buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.PRINT_REPORT]}
          onClick={handlePrintReport}
        />
      ),
    [handlePrintReport, props.buttonLoadCheck, selectedOrder?.status]
  );

  const iconPacs = useMemo(() => {
    const isReadyToView =
      selectedOrder?.ready_to_view || (CONFIG.PACS_BASE_URL && selectedOrder?.accession_id);

    return (
      <ActionButton
        id={`CardImagingResult-ActionButton-PACS`}
        badgeSx={styles.pacsIcon}
        disabled={!isReadyToView}
        icon={isReadyToView ? "pacs" : "pacs_grey"}
        loading={props.buttonLoadCheck?.[BTN_ACTS.OPEN_PACS]}
        onClick={handleClickPacs}
      />
    );
  }, [handleClickPacs, props.buttonLoadCheck, selectedOrder?.ready_to_view]);

  const pagination = useMemo(() => {
    const orderList = props.ImagingResultSequence?.orderList || [];
    const totalPages = Math.ceil(orderList.length / LIMIT);

    return (
      <Pagination
        activePage={activePage}
        ellipsisItem={PAGINATION_CONFIG.ellipsis}
        firstItem={PAGINATION_CONFIG.first}
        lastItem={PAGINATION_CONFIG.last}
        nextItem={PAGINATION_CONFIG.next}
        prevItem={PAGINATION_CONFIG.prev}
        size="mini"
        totalPages={totalPages}
        // callback
        onPageChange={handlePageChange}
      />
    );
  }, [activePage, handlePageChange, props.ImagingResultSequence?.orderList]);

  const principalDiagLabel = useMemo(
    () => <Label style={styles.label}>{suspectedDiagnosisName}</Label>,
    [suspectedDiagnosisName]
  );

  const inputPassword = useMemo(
    () => (
      <PasswordRecheck
        id="CardImagingResult-Input-Password"
        password={props.ImagingResultSequence?.reportDetail?.password || ""}
        setPassword={handleChangePassword}
      />
    ),
    [handleChangePassword, props.ImagingResultSequence?.reportDetail]
  );

  const masterData = useMemo(
    () => ({
      clinicaltags: props.clinicaltags,
      organ: props.organ,
    }),
    [props.clinicaltags, props.organ]
  );

  console.log("CardImagingResultUX", props);

  return (
    <div id="CardImagingResult" style={{ paddingBottom: "2rem", ...props.cardStyle }}>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_IMAGING_RESULT]}
        success={null}
        onClose={handleCloseErrMsg}
      />

      <CardImagingResultUX
        // data
        buttonApprove={buttonApprove}
        buttonEdit={buttonEdit}
        buttonPrintReport={buttonPrintReport}
        divisionName={divisionName}
        executionNote={executionNote}
        hideColumnResult={props.hideColumnResult}
        iconPacs={iconPacs}
        imagingItemDetail={props.ImagingResultSequence?.imagingItemDetail}
        imagingOrderList={imagingOrderItems}
        inputPassword={inputPassword}
        languageUX={props.languageUX}
        locationsName={locationsName}
        pagination={pagination}
        principalDiagLabel={principalDiagLabel}
        principalDiagnosis={props.ImagingResultSequence?.principalDiagnosis}
        readOnly={readOnly}
        reportDetail={props.ImagingResultSequence?.reportDetail}
        selectedOrder={selectedOrder}
        showApprove={showApprove}
        showEdit={orderItemStatus !== ORDER_STATUS.APPROVED && isRadiologist && !props.readOnly}
        showEditReason={showEditReason}
        statusDetail={statusDetail}
        tableHeight={props.tableHeight}
        // options
        resultSummaryOptions={props.masterOptions?.resultSummary}
        // callback
        onChangeValue={handleChangeValue}
        onClose={handleClose}
        onGetTrProps={handleGetTrProps}
      />

      <ModXrayDetail
        onEvent={props.onEvent}
        setProp={props.setProp}
        clinicalFindingId={props.clinicalFindingId}
        clinicalFindingIndex={props.clinicalFindingIndex}
        clinicalFindings={props.clinicalFindings}
        data={props.modXrayDetail}
        filterClinicalFindingIds={props.filterClinicalFindingIds}
        // data
        open={!!props.modXrayDetail}
        // config
        readOnly
        masterData={masterData}
        // options
        eligibilityOptions={props.masterOptions?.eligibilityType}
        // callback
        onClose={handleCloseModXray}
        // onSave={handleSaveXray}
      />

      <ModImagingAuditLog
        auditLogList={props.ImagingResultSequence?.auditLogList}
        data={modAuditLog}
        // data
        open={!!modAuditLog}
        // callback
        onClose={handleCloseModLog}
      />

      <ModalGallery
        data={props.ImagingResultSequence?.pacsGalleryDetail || null}
        languageUX={props.languageUX}
        open={!!props.ImagingResultSequence?.pacsGalleryDetail}
        onClose={handleCloseModalGallery}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                    ButtonLoadCheck;                    */

/* ------------------------------------------------------ */
type ButtonLoadCheckCustomProps = {
  setProp: SetProp;
  // data
  action: [ReportActionType, ReportActionType];
  color: [SemanticCOLORS, SemanticCOLORS];
  disabled?: boolean;
  // config
  switch: boolean;
  title: ["SAVE" | ReportActionType, ReportActionType];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  onClick: (action: ReportActionType) => any;
};

const ButtonLoadCheckCustom = (props: ButtonLoadCheckCustomProps) => {
  // Callback
  const handleClick = useCallback(() => {
    props.onClick(props.switch ? props.action[1] : props.action[0]);
  }, [props.action, props.switch]);

  // Memo
  const action = useMemo(
    () => (props.switch ? props.action[1] : props.action[0]),
    [props.action, props.switch]
  );

  const buttonKey = useMemo(() => `EDIT_${ACTIONS[action]}`, [action, props.switch]);

  return (
    <ButtonLoadCheck
      // function
      setProp={props.setProp}
      color={props.switch ? props.color[1] : props.color[0]}
      // config
      disabled={props.disabled}
      name={buttonKey}
      // data
      paramKey={`${CARD_IMAGING_RESULT}_${buttonKey}`}
      size="medium"
      style={styles.button}
      title={props.switch ? props.title[1] : props.title[0]}
      fluid
      buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_IMAGING_RESULT}_${buttonKey}`]}
      onClick={handleClick}
    />
  );
};

CardImagingResult.displayName = "CardImagingResult";

export default React.memo(CardImagingResult);
