import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Icon,
  FormGroup,
  FormField,
  Label,
  Checkbox,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardImagingOrderUX = (props: any) => {
    return(
      <div
        id="CardImagingOrderUX-div-0"
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          id="CardImagingOrderUX-div-1"
          style={{display: props.editMode?"none":""}}>
          
          <div
            id="CardImagingOrder-Div-ImagingRequestScreen"
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            
            <div
              id="CardImagingOrderUX-div-6"
              style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0", display: "flex", alignItems: "baseline"}}>
              
              <div
                id="CardImagingOrderUX-div-97"
                style={{marginRight: "0.5rem"}}>
                Imaging Request
              </div>
              <div
                id="CardImagingOrderUX-div-104"
                style={{display:  props.isOpenRIS?"": "none" }}>
                {props.refreshIcon}
              </div>
            </div>
            <div
              id="CardImagingOrderUX-div-7">
              
              <Button
                color="green"
                disabled={props.disabledAddOrder}
                id="CardImagingOrderUX-Button-8"
                onClick={props.onAddOrder}
                style={{position: "relative", display:  props.isOpenRIS?"none": "flex", padding: "0.65em 0.75em 0.45rem"}}>
                <div
                  id="CardImagingOrderUX-div-13"
                  style={{fontSize: "1.2rem"}}>
                  
                  <Icon
                    id="CardImagingOrderUX-Icon-14"
                    name="file text outline">
                  </Icon>
                </div>
                <label
                  id="CardImagingOrderUX-label-10">
                  Add order
                </label>
                <div
                  id="CardImagingOrderUX-div-11"
                  style={{position: "absolute", fontSize: "0.45rem", left: "16.5px", top: "11px"}}>
                  
                  <Icon
                    id="CardImagingOrderUX-Icon-12"
                    name="plus">
                  </Icon>
                </div>
              </Button>
              <Button
                color="green"
                id="CardImagingOrderUX-Button-96"
                onClick={props.onOpenRIS}
                style={{position: "relative", display:  props.isOpenRIS?"flex": "none", alignItems: "center",padding: "0.3em 0.75em 0.275rem"}}>
                
                <img
                  id="CardImagingOrderUX-img-100"
                  src="/static/images/x-ray/ris-link-chain.png"
                  style={{height: "25px", marginRight: "0.5rem"}}>
                </img>
                <label
                  id="CardImagingOrderUX-label-99">
                  Open RIS
                </label>
              </Button>
            </div>
          </div>
        </div>
        <div
          className="ui divider"
          id="CardImagingOrderUX-div-2"
          style={{marginTop: "0.5rem",display: props.editMode?"none":""}}>
          
        </div>
        <div
          className="ui form"
          id="CardImagingOrderUX-div-4"
          style={{display: props.editMode?"none":""}}>
          
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.imagingRequestList || []}
            getTrProps={props.onGetTrPropsRequest}
            headers={`Order ID, Request date, Order status, Detail, Item status, Careprovider${props.isOpenRIS?"":", Del, Edit"}`}
            id="CardImagingOrderUX-Table-15"
            keys={`order_id,request_date,order_status,summary, status,doctor_name${props.isOpenRIS?"":",delete,edit"}`}
            minRows={!!props.imagingItemDetail ? 7 : 11}
            showPagination={false}
            style={{height: !!props.imagingItemDetail ? "calc(100vh - 33rem)" : "calc(100vh - 10rem)"}}
            widths={`^120,^120,^120,^120,150,^120${props.isOpenRIS?"":",^80,^80"}`}>
          </Table>
        </div>
        <div
          className="ui form"
          id="CardImagingOrderUX-div-16"
          style={{marginTop: props.editMode?"0.25rem": "1.5rem", display: !!props.imagingItemDetail ? "": "none"}}>
          
          <FormGroup
            id="CardImagingOrderUX-FormGroup-17"
            inline={true}>
            <FormField
              id="CardImagingOrderUX-FormField-18"
              inline={true}>
              <label
                id="CardImagingOrderUX-label-20">
                {!!props.imagingItemDetail?.id ? "แก้ไขรายการคำสั่ง Imaging": "รายการคำสั่ง Imaging"}
              </label>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-19"
              inline={true}
              style={{display: !!props.imagingItemDetail?"":"none"}}>
              <Label
                color="teal"
                id="CardImagingOrderUX-Label-21">
                {props.imagingItemDetail?.order_id || "-"}
              </Label>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-70"
              style={{flex: 1}}>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-71">
              <Icon
                className="red large"
                id="CardImagingOrder-Icon-CloseImagingDetail"
                link={true}
                name="close"
                onClick={props.onClose}>
              </Icon>
            </FormField>
          </FormGroup>
          <FormGroup
            className={props.readOnly? "--override-disabled": ""}
            id="CardImagingOrderUX-FormGroup-22"
            inline={true}
            style={{backgroundColor: "#e5f4ff", padding: "0.75rem"}}>
            <FormField
              id="CardImagingOrderUX-FormField-23"
              inline={true}>
              <div
                className="label-suspected-diagnosis"
                id="CardImagingOrderUX-div-29"
                style={{width:"133px"}}>
                
                <label
                  id="CardImagingOrderUX-label-30">
                  Suspected Diagnosis
                </label>
                <label
                  id="CardImagingOrderUX-label-31"
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-24"
              inline={true}
              width={10}>
              <div
                id="CardImagingOrderUX-div-36"
                style={{width: "100%", marginRight: "0.75rem"}}>
                {props.SearchBoxPrincipalDiag}
              </div>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-25"
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.out_time || false}
                disabled={props.readOnly || false}
                id="CardImagingOrderUX-Checkbox-32"
                label="นอกเวลา"
                name="extra.out_time"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-26"
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.is_emergency|| false}
                disabled={props.readOnly || false}
                id="CardImagingOrderUX-Checkbox-33"
                label="Emergency"
                name="is_emergency"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-27"
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.secret|| false}
                disabled={props.readOnly || false}
                id="CardImagingOrderUX-Checkbox-34"
                label="ปกปิด"
                name="extra.secret"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-73"
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.lawsuit|| false}
                disabled={props.readOnly || false}
                id="CardImagingOrderUX-Checkbox-74"
                label="คดีความ"
                name="extra.lawsuit"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-28"
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.repeat_medication|| false}
                disabled={props.readOnly || false}
                id="CardImagingOrderUX-Checkbox-35"
                label="Repeat Medication"
                name="extra.repeat_medication"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardImagingOrderUX-FormGroup-37"
            inline={true}>
            <FormField
              id="CardImagingOrderUX-FormField-38"
              inline={true}>
              <label
                id="CardImagingOrderUX-label-43"
                style={{minWidth: "max-content"}}>
                Imaging Item
              </label>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-39"
              inline={true}
              width="16">
              <div
                id="CardImagingOrderUX-div-44"
                style={{width: "100%"}}>
                {props.SearchBoxImagingItem}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          className={props.readOnly? "--override-disabled": ""}
          id="CardImagingOrderUX-div-47"
          style={{display: !!props.imagingItemDetail? "":"none"}}>
          
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.imagingItemList || []}
            getTrProps={props.onGetTrPropsItem}
            headers={`No., Code, Imaging item, X-ray Group, QTY, Unit, Price/Unit, Total price, เบิกได้,เบิกไม่ได้,Detail${props.isOpenRIS?"":", Del"}`}
            id="CardImagingOrderUX-Table-48"
            keys={`no, code, name,group_name,qty,unit_name,price_unit,price_total,price_claimable,price_non_claimable,summary${props.isOpenRIS?"":",delete"}`}
            minRows={4}
            showPagination={false}
            style={{height: "300px"}}
            widths={`^50,^90,^120,^120,^90,^90,^90,^90,^90,^90,^100${props.isOpenRIS?"":",^80"}`}>
          </Table>
        </div>
        <div
          className={`ui form${props.readOnly? " --override-disabled --grey": ""}`}
          id="CardImagingOrderUX-div-49"
          style={{marginTop: "1.5rem", marginBottom: props.editMode?"": "3rem",display: !!props.imagingItemDetail? "":"none"}}>
          
          <FormGroup
            className="--override-disabled --grey"
            id="CardImagingOrderUX-FormGroup-50"
            inline={true}
            style={{marginBottom: "1.25rem"}}>
            <FormField
              id="CardImagingOrderUX-FormField-101"
              inline={true}
              style={{display:props.isOpenRIS?"":"none"}}
              width={5}>
              <label
                id="CardImagingOrderUX-label-102"
                style={{minWidth: "max-content"}}>
                แผนกที่สั่ง
              </label>
              <Dropdown
                disabled={true}
                fluid={true}
                icon="search"
                id="CardImagingOrderUX-Dropdown-103"
                options={props.divisionForOrderDivOptions || []}
                selection={true}
                style={{width: "100%", minWidth:"15rem"}}
                value={props.imagingItemDetail?.order_div || ""}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-52"
              inline={true}
              style={{flex: 1}}>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-54"
              inline={true}
              width={3}>
              <label
                id="CardImagingOrderUX-label-77"
                style={{minWidth: "max-content", width:"72px", textAlign:"right"}}>
                ราคาทั้งหมด
              </label>
              <Input
                disabled={true}
                fluid={true}
                id="CardImagingOrderUX-Input-67"
                style={{width: "100%", minWidth:"5rem"}}
                value={props.summaryPrice?.total || ""}>
              </Input>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-56"
              inline={true}
              width={3}>
              <label
                id="CardImagingOrderUX-label-76"
                style={{minWidth: "max-content" , width:"72px", textAlign:"right"}}>
                เบิกได้
              </label>
              <Input
                disabled={true}
                fluid={true}
                id="CardImagingOrderUX-Input-66"
                style={{width: "100%", minWidth:"5rem"}}
                value={props.summaryPrice?.claimable || ""}>
              </Input>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-58"
              inline={true}
              width={3}>
              <label
                id="CardImagingOrderUX-label-75"
                style={{minWidth: "max-content", width:"72px", textAlign:"right"}}>
                เบิกไม่ได้
              </label>
              <Input
                disabled={true}
                fluid={true}
                id="CardImagingOrderUX-Input-65"
                style={{width: "100%", minWidth:"5rem"}}
                value={props.summaryPrice?.non_claimable || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardImagingOrderUX-FormGroup-86"
            inline={true}>
            <FormField
              id="CardImagingOrderUX-FormField-80"
              inline={true}
              style={{display:props.isOpenRIS?"none":""}}
              width="1">
              
              <label
                id="CardImagingOrderUX-label-93"
                style={{minWidth: "max-content", }}>
                แผนกที่สั่ง
              </label>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-83"
              inline={true}
              style={{display:props.isOpenRIS?"none":""}}
              width="5">
              <Dropdown
                disabled={props.readOnly || false}
                fluid={true}
                icon="search"
                id="CardImagingOrderUX-Dropdown-85"
                name="order_div"
                onChange={props.onChangeValue}
                options={props.divisionForOrderDivOptions || []}
                search={true}
                selection={true}
                style={{width: "100%", minWidth:"15rem"}}
                value={props.imagingItemDetail?.order_div || ""}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-94"
              inline={true}
              style={{flex:1}}>
              
            </FormField>
            <FormField
              className="required"
              id="CardImagingOrderUX-FormField-87"
              inline={true}>
              <div
                id="CardImagingOrderUX-div-95">
                {props.orderByBox}
              </div>
            </FormField>
            <FormField
              id="CardImagingOrderUX-FormField-59"
              inline={true}>
              <div
                id="CardImagingOrderUX-div-69">
                {props.ButtonConfirmOrder}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

CardImagingOrderUX.displayName = "CardImagingOrderUX";
export default React.memo(CardImagingOrderUX)

export const screenPropsDefault = {}

/* Date Time : Mon Feb 03 2025 16:33:35 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{display: props.editMode?\"none\":\"\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-2"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.5rem\",display: props.editMode?\"none\":\"\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-4"
        },
        "style": {
          "type": "code",
          "value": "{display: props.editMode?\"none\":\"\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrder-Div-ImagingRequestScreen"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-6"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\", display: \"flex\", alignItems: \"baseline\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-7"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 7,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAddOrder"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Button-8"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddOrder"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\", display:  props.isOpenRIS?\"none\": \"flex\", padding: \"0.65em 0.75em 0.45rem\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Add order"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-10"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-11"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", fontSize: \"0.45rem\", left: \"16.5px\", top: \"11px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Icon",
      "parent": 11,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Icon-12"
        },
        "name": {
          "type": "value",
          "value": "plus"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-13"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Icon",
      "parent": 13,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Icon-14"
        },
        "name": {
          "type": "value",
          "value": "file text outline"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.imagingRequestList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrPropsRequest"
        },
        "headers": {
          "type": "code",
          "value": "`Order ID, Request date, Order status, Detail, Item status, Careprovider${props.isOpenRIS?\"\":\", Del, Edit\"}`"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Table-15"
        },
        "keys": {
          "type": "code",
          "value": "`order_id,request_date,order_status,summary, status,doctor_name${props.isOpenRIS?\"\":\",delete,edit\"}`"
        },
        "minRows": {
          "type": "code",
          "value": "!!props.imagingItemDetail ? 7 : 11"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: !!props.imagingItemDetail ? \"calc(100vh - 33rem)\" : \"calc(100vh - 10rem)\"}"
        },
        "widths": {
          "type": "code",
          "value": "`^120,^120,^120,^120,150,^120${props.isOpenRIS?\"\":\",^80,^80\"}`"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-16"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: props.editMode?\"0.25rem\": \"1.5rem\", display: !!props.imagingItemDetail ? \"\": \"none\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 16,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormGroup-17"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-18"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-19"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: !!props.imagingItemDetail?\"\":\"none\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "!!props.imagingItemDetail?.id ? \"แก้ไขรายการคำสั่ง Imaging\": \"รายการคำสั่ง Imaging\""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-20"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Label",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.imagingItemDetail?.order_id || \"-\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Label-21"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 16,
      "props": {
        "className": {
          "type": "code",
          "value": "props.readOnly? \"--override-disabled\": \"\""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormGroup-22"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#e5f4ff\", padding: \"0.75rem\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-23"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-24"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-25"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-26"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-27"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-28"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "label-suspected-diagnosis"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-29"
        },
        "style": {
          "type": "code",
          "value": "{width:\"133px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "Suspected Diagnosis"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-30"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-31"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Checkbox",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.out_time || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Checkbox-32"
        },
        "label": {
          "type": "value",
          "value": "นอกเวลา"
        },
        "name": {
          "type": "value",
          "value": "extra.out_time"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Checkbox",
      "parent": 26,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.is_emergency|| false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Checkbox-33"
        },
        "label": {
          "type": "value",
          "value": "Emergency"
        },
        "name": {
          "type": "value",
          "value": "is_emergency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.secret|| false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Checkbox-34"
        },
        "label": {
          "type": "value",
          "value": "ปกปิด"
        },
        "name": {
          "type": "value",
          "value": "extra.secret"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.repeat_medication|| false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Checkbox-35"
        },
        "label": {
          "type": "value",
          "value": "Repeat Medication"
        },
        "name": {
          "type": "value",
          "value": "extra.repeat_medication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxPrincipalDiag"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-36"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"0.75rem\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormGroup",
      "parent": 16,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormGroup-37"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-38"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-39"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "16"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging Item"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-43"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxImagingItem"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-44"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "props.readOnly? \"--override-disabled\": \"\""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-47"
        },
        "style": {
          "type": "code",
          "value": "{display: !!props.imagingItemDetail? \"\":\"none\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 48,
      "name": "Table",
      "parent": 47,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.imagingItemList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrPropsItem"
        },
        "headers": {
          "type": "code",
          "value": "`No., Code, Imaging item, X-ray Group, QTY, Unit, Price/Unit, Total price, เบิกได้,เบิกไม่ได้,Detail${props.isOpenRIS?\"\":\", Del\"}`"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Table-48"
        },
        "keys": {
          "type": "code",
          "value": "`no, code, name,group_name,qty,unit_name,price_unit,price_total,price_claimable,price_non_claimable,summary${props.isOpenRIS?\"\":\",delete\"}`"
        },
        "minRows": {
          "type": "code",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        },
        "widths": {
          "type": "code",
          "value": "`^50,^90,^120,^120,^90,^90,^90,^90,^90,^90,^100${props.isOpenRIS?\"\":\",^80\"}`"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form${props.readOnly? \" --override-disabled --grey\": \"\"}`"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-49"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\", marginBottom: props.editMode?\"\": \"3rem\",display: !!props.imagingItemDetail? \"\":\"none\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormGroup",
      "parent": 49,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormGroup-50"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.25rem\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-52"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-54"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-56"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-58"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 86,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-59"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Input",
      "parent": 58,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Input-65"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "ref": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth:\"5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.summaryPrice?.non_claimable || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Input",
      "parent": 56,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Input-66"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "ref": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth:\"5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.summaryPrice?.claimable || \"\""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Input",
      "parent": 54,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Input-67"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "ref": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth:\"5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.summaryPrice?.total || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirmOrder"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-69"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-70"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-71"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Icon",
      "parent": 71,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrder-Icon-CloseImagingDetail"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-73"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Checkbox",
      "parent": 73,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.lawsuit|| false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Checkbox-74"
        },
        "label": {
          "type": "value",
          "value": "คดีความ"
        },
        "name": {
          "type": "value",
          "value": "extra.lawsuit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-75"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", width:\"72px\", textAlign:\"right\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-76"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\" , width:\"72px\", textAlign:\"right\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาทั้งหมด"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-77"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", width:\"72px\", textAlign:\"right\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-80"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:props.isOpenRIS?\"none\":\"\"}"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 86,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-83"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:props.isOpenRIS?\"none\":\"\"}"
        },
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Dropdown",
      "parent": 83,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Dropdown-85"
        },
        "name": {
          "type": "value",
          "value": "order_div"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionForOrderDivOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth:\"15rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.imagingItemDetail?.order_div || \"\""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormGroup",
      "parent": 49,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormGroup-86"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 86,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-87"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-93"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormField",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-94"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderByBox"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-95"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Button-96"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenRIS"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\", display:  props.isOpenRIS?\"flex\": \"none\", alignItems: \"center\",padding: \"0.3em 0.75em 0.275rem\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging Request"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-97"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "label",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": "Open RIS"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-99"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "img",
      "parent": 96,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-img-100"
        },
        "src": {
          "type": "value",
          "value": "/static/images/x-ray/ris-link-chain.png"
        },
        "style": {
          "type": "code",
          "value": "{height: \"25px\", marginRight: \"0.5rem\"}"
        }
      },
      "seq": 99,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-FormField-101"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:props.isOpenRIS?\"\":\"none\"}"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-label-102"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Dropdown",
      "parent": 101,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-Dropdown-103"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "props.divisionForOrderDivOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth:\"15rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.imagingItemDetail?.order_div || \"\""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.refreshIcon"
        },
        "id": {
          "type": "value",
          "value": "CardImagingOrderUX-div-104"
        },
        "style": {
          "type": "code",
          "value": "{display:  props.isOpenRIS?\"\": \"none\" }"
        }
      },
      "seq": 104,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardImagingOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
