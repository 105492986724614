import React, { CSSProperties, useMemo } from "react";

import { Dimmer, Loader } from "semantic-ui-react";

// Mui
import Badge, { BadgeProps } from "@mui/material/Badge";
import { useIntl } from "react-intl";

type IconType =
  | "approve"
  | "edit"
  | "execute"
  | "pacs"
  | "pacs_grey"
  | "register"
  | "report"
  | "result"
  | "unapprove"
  | "unexecute"
  | "unregister";

type ActionButtonProps = {
  id?: any;
  badgeContent?: React.ReactNode;
  // style
  badgeSx?: BadgeProps["sx"];
  disabled?: boolean;
  icon: IconType;
  loading?: boolean;
  onClick?: (icon: IconType) => any;
};

// Const
const styles = {
  box_icon: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    fontSize: "0.6rem",
  } as CSSProperties,
  icon_label: {
    fontWeight: "bold",
    lineHeight: 1.3,
    textAlign: "center",
  } as CSSProperties,
};

const IMAGES = {
  approve: "/static/images/x-ray/document-table-checkmark.png",
  comment_green: "/static/images/x-ray/comment-outline-green.png",
  comment_grey: "/static/images/x-ray/comment-outline-grey.png",
  edit: "/static/images/x-ray/edit-square-sharp.png",
  execute: "/static/images/x-ray/double-check-execute.png",
  female: "/static/images/x-ray/female.png",
  male: "/static/images/x-ray/male.png",
  pacs: "/static/images/x-ray/photo-library-blue.png",
  pacs_grey: "/static/images/x-ray/image-gallery-grey.png",
  register: "/static/images/x-ray/file-check-register.png",
  report: "/static/images/x-ray/document-add.png",
  result: "/static/images/x-ray/eye-grey.png",
  unapprove: "/static/images/x-ray/document-dismiss.png",
  unexecute: "/static/images/x-ray/uncheck-read.png",
  unregister: "/static/images/x-ray/document-cancel.png",
};

export const ActionButton = (props: ActionButtonProps) => {
  const intl = useIntl();
  const config = useMemo(
    () =>
      ({
        approve: {
          alt: "green document table with a check mark on it",
          color: "#1B9D2C",
          label: "Approve diagnostic radiography results",
          labelStyle: { maxWidth: "65px" },
          style: { width: "30px" },
          title: "APPROVE RESULT",
        },
        edit: {
          alt: "yellow pencil",
          color: "#F7AC08",
          label: "edit diagnostic radiographic results",
          labelStyle: { maxWidth: "45px" },
          style: { margin: "0 0 4px", width: "26px" },
          title: "EDIT RESULT",
        },
        execute: {
          alt: "yellow square with a check mark on it",
          color: "#F7AC08",
          label: "execute imaging order",
          style: { margin: "3px 0px 5px", width: "22px" },
          title: "EXECUTE",
        },
        pacs: {
          alt: "blue image gallery",
          color: "#2185D0",
          label: "view diagnostic radiographs in the PACS system",
          style: { margin: "4px 0 1px", width: "25px" },
          title: "PACS",
        },
        pacs_grey: {
          alt: "grey image gallery",
          color: "#B8B8B8",
          label: "",
          style: { margin: "4px 0 1px", width: "25px" },
          title: "PACS",
        },
        register: {
          alt: "blue folder with a check mark on it",
          color: "#2185D0",
          label: "register imaging order",
          style: { width: "30px" },
          title: "REGISTER",
        },
        report: {
          alt: "purple icon with a pen and paper",
          color: "#9B51E0",
          label: "enter the results of the diagnostic radiography.",
          style: { margin: "1px 0 1px", width: "28px" },
          title: "REPORT ENTRY",
        },
        result: {
          alt: "grey eye",
          color: "#898383",
          label: "view diagnostic radiology report",
          style: { margin: "1px 0 1px", width: "28px" },
          title: "RESULT",
        },
        unapprove: {
          alt: "red document with a dismiss icon on it",
          color: "#FA3030",
          label: "revert the status from approved back to reported",
          style: { margin: "1px 0px 1px 2px", width: "28px" },
          title: "UNAPPROVE",
        },
        unexecute: {
          alt: "red check mark with a dismiss icon on it",
          color: "#FA3030",
          label: "unexecute imaging order",
          style: { margin: "1px 0px 1px 2px", width: "28px" },
          title: "UNEXECUTE",
        },
        unregister: {
          alt: "red document with a check mark and X on it",
          color: "#FA3030",
          label: "unregister imaging order",
          style: { width: "30px" },
          title: "UNREGISTER",
        },
      }[props.icon]),
    [props.icon]
  );

  const handleClick = () => {
    if (!props.disabled) {
      props.onClick?.(props.icon);
    }
  };

  return (
    <div>
      <Badge
        badgeContent={props.badgeContent}
        color="error"
        sx={{
          "& .MuiBadge-badge": {
            height: "15px",
            margin: "1px 5px 0 0",
            minWidth: "15px",
            padding: "0 2px",
          },
          position: "relative",
          ...props.badgeSx,
        }}
      >
        <div
          id={props.id ? `Div-${props.id}` : `ActionButton-Div`}
          aria-hidden="true"
          aria-label={config.label}
          onClick={handleClick}
          style={{
            ...styles.box_icon,
            cursor: props.disabled ? "" : "pointer",
          }}
        >
          <img id={props.id ? `Img-${props.id}` : `ActionButton-Img`} alt={config.alt} src={IMAGES[props.icon]} style={config.style} />
          <label
            id={props.id ? `Label-${props.id}` : `ActionButton-Label`}
            style={{
              color: config.color,
              ...styles.icon_label,
              ...config.labelStyle,
            }}
          >
            {config.title}
          </label>
        </div>

        <Dimmer
          active={props.loading}
          size="small"
          inverted
          style={{
            background: "transparent",
            margin: "-1px 0 0 0px",
            maxWidth: config.labelStyle?.maxWidth,
            padding: 0,
          }}
        >
          <Loader size="small" inline />
        </Dimmer>
      </Badge>
    </div>
  );
};

ActionButton.displayName = "ActionButton";

export default React.memo(ActionButton);
